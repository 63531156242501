import Vue from 'vue'
import VueRouter from 'vue-router'
import menuRoutes from './menuRoutes'

Vue.use(VueRouter)

const routes = [
  ...menuRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})
export default router
