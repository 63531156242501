let pagePosition = 0
const getElementPagePosition = function (element) {
  const offsetTop = element.offsetTop
  const parentElement = element.offsetParent
  pagePosition += offsetTop
  if(parentElement) {
    getElementPagePosition(parentElement)
  }
}
/**
 * @param {string} elementName
 */
export function scrollTo(elementName) {
  const element = document.getElementsByName(elementName)[0]
  if(!element) return
  getElementPagePosition(element)
  document.documentElement.scrollTo(0, pagePosition)
  pagePosition = 0
}
