import Vue from 'vue'
import {
  Carousel,
  CarouselItem,
  Tabs,
  TabPane
} from 'element-ui'
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-variables.scss'

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tabs)
Vue.use(TabPane)