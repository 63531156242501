import layout from '@/layout/index.vue'
// 由于Nav引用，子路由路径必须写全路径
export default [
  {
    path: '/',
    component: layout,
    meta: {nameCN: '首页', hasSubmenu: false},
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      }
    ]
  },
  {
    path: '/products',
    component: layout,
    meta: {nameCN: '基础产品', hasSubmenu: false},
    children: [
      //没有子菜单的菜单写法
      {
        path: '/products',
        name: 'Products',
        component: () => import('@/views/Products/index.vue'),
      }
      //有子菜单的写法
      // {
      //   path: '/products/menu1',
      //   name: 'menu1',
      //   meta: { nameCN: '菜单一', isSubmenu: true },
      //   component: () => import('@/views/Products/menu1/index.vue'),
      // },
      // {
      //   path: '/products/menu2',
      //   name: 'menu2',
      //   meta: { nameCN: '菜单二', isSubmenu: true },
      //   component: () => import('@/views/Products/menu2/index.vue'),
      // },
      // {
      //   path: '/products/menu3',
      //   name: 'menu3',
      //   meta: { nameCN: '菜单三', isSubmenu: true },
      //   component: () => import('@/views/Products/menu3/index.vue'),
      // }
    ]
  },
  {
    path: '/situation',
    component: layout,
    meta: {nameCN: '应用场景', hasSubmenu: false},
    children: [
      {
        path: '/situation',
        name: 'Situation',
        component: () => import('@/views/Situation/index.vue'),
      }
    ]
  },
  // {
  //   path: '/solution',
  //   component: layout,
  //   meta: {nameCN: '解决方案', hasSubmenu: false},
  //   children: [
  //     {
  //       path: '/solution',
  //       name: 'Solution',
  //       component: () => import('@/views/Solution/index.vue'),
  //     }
  //   ]
  // },
  // {
  //   path: '/service',
  //   component: layout,
  //   meta: {nameCN: '服务体系', hasSubmenu: false},
  //   children: [
  //     {
  //       path: '/service',
  //       name: 'Service',
  //       component: () => import('@/views/Service/index.vue'),
  //     }
  //   ]
  // },
  {
    path: '/about',
    component: layout,
    meta: {nameCN: '关于我们', hasSubmenu: false},
    children: [
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About/index.vue'),
      }
    ]
  }
]