<template>
  <div class="layout">
    <JcNav />
    <main>
      <router-view/>
    </main>
    <JcFooter />
  </div>
</template>

<script>
import JcNav from '@/components/JcNav'
import JcFooter from '@/components/JcFooter'

export default {
  components: {
    JcNav,
    JcFooter
  },
}
</script>
