import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showNav: true,
    aboutUsElementName: 'about-us'
  },
  mutations: {
    Set_ShowNav(state, value) {
      state.showNav = value
    }
  },
  actions: {
  },
  modules: {
  }
})
