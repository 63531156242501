<template>
  <footer>
    <div class="color-image-line"></div>
    <div class="footer-content">
      <div class="footer-logo-wrap">
        <img src="@/assets/imgs/logo.png" alt="">
        <span class="footer-logo-text">矩尺网络</span>
        <span class="footer-logo-desc">服务器负载均衡服务商</span>
      </div>
      <div class="footer-list-wrap">
        <div class="footer-list-item">
          <div class="footer-list-item-munu-l1">
            <span>首页</span>
          </div>
          <div class="footer-list-item-munu-l2">
            <!-- <a v-if="NODE_ENV === 'production'" :href="origin">官网首页</a>
            <router-link v-else-if="NODE_ENV === 'development'" :to="{ path: '/' }">官网首页</router-link> -->
            <router-link :to="{ path: '/' }">官网首页</router-link>
          </div>
        </div>
        <div class="footer-list-item">
          <div class="footer-list-item-munu-l1">
            <span>基础产品</span>
          </div>
          <div class="footer-list-item-munu-l2">
            <!-- <a v-if="NODE_ENV === 'production'" :href="origin + '/products/index.html'">基础产品</a>
            <router-link v-else-if="NODE_ENV === 'development'" :to="{ path: '/products' }">基础产品</router-link> -->
            <router-link :to="{ path: '/products' }">基础产品</router-link>
          </div>
        </div>
        <div class="footer-list-item">
          <div class="footer-list-item-munu-l1">
            <span>应用场景</span>
          </div>
          <div class="footer-list-item-munu-l2">
            <!-- <a v-if="NODE_ENV === 'production'" :href="origin + '/situation/index.html'">应用场景</a>
            <router-link v-else-if="NODE_ENV === 'development'" :to="{ path: '/situation' }">应用场景</router-link> -->
            <router-link :to="{ path: '/situation' }">应用场景</router-link>
          </div>
        </div>
        <div class="footer-list-item">
          <div class="footer-list-item-munu-l1">
            <span>关于我们</span>
          </div>
          <div class="footer-list-item-munu-l2">
            <!-- <a v-if="NODE_ENV === 'production'" :href="origin + '/about/index.html?scroll=' + $store.state.aboutUsElementName" @click="handleAboutUsClick">公司简介</a>
            <router-link v-else-if="NODE_ENV === 'development'" :to="{ path: '/about', query: {scroll: $store.state.aboutUsElementName} }" @click.native="handleAboutUsClick">公司简介</router-link> -->
            <router-link :to="{ path: '/about', query: {scroll: $store.state.aboutUsElementName} }" @click.native="handleAboutUsClick">公司简介</router-link>
          </div>
        </div>
        <div class="footer-list-item">
          <div class="footer-list-item-munu-l1">
            <span>联系我们</span>
          </div>
          <div class="footer-list-item-munu-l2 contact-us">
            <p>公司地址：浙江省杭州市滨江区滨盛路1509号天恒大厦5楼507</p>
            <p>客服热线：0571-86833186</p>
            <p>邮箱：business@n6delta.com</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="footer-devide"></div> -->
    <div class="footer-copyright">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" style="margin-right: 10px;">浙ICP备2021038441号</a>
      <a target="_blank" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + recordNum">
        <img src="@/assets/imgs/security-record.png" style="vertical-align: bottom;"/>
        <span>浙公网安备 {{recordNum}}号</span >
      </a>
    </div>
  </footer>
</template>

<script>
import {scrollTo} from '@/utils'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      recordNum: '33010802012942', //浙公网安备案号
      origin: location.origin,
      NODE_ENV: process.env.NODE_ENV
    }
  }, 
  methods: {
    handleAboutUsClick() {
      if(this.$route.path === '/about') {
        scrollTo(this.$store.state.aboutUsElementName)
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
footer{
  width: 100%;
  min-width: 1440px;
  background-color: #3d3d3d;
}
.footer-content{
  width: 1440px;
  padding: 20px 104px;
  margin: 0 auto;
}
.footer-logo-wrap{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  img {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    margin-right: 10px;
  }
  .footer-logo-text{
    font-size: $logoTextSize;
    color: $primaryColor;
    margin-right: 30px;
  }
  .footer-logo-desc{
    font-size: 20px;
    color: #aaaaaa;
  }
}
.footer-list-wrap{
  display: flex;
  .footer-list-item{
    width: 82px;
    margin-right: 150px;
    .footer-list-item-munu-l1{
      padding: 5px 8px;
      border-bottom: 1px solid #5b5a5a;
      span {
        color: #aaaaaa;
        font-size: 16px;
      }
    }
    .footer-list-item-munu-l2{
      font-size: 14px;
      text-align: left;
      color: #F2F2F2;
      a {
        display: block;
        margin: 1em 0;
      }
    }
    .contact-us p{
      white-space: nowrap;
    }
  }
}
.footer-devide{
  width: 100%;
  border-top: 1px solid #5b5a5a;
}
.footer-copyright{
  width: 1440px;
  padding: 10px 104px;
  margin: 0 auto;
  a{
    color: #aaa;
    font-size: 14px;
  }
}
</style>
