<template>
  <nav class="nav-wrap" :class="{'fixed-nav': $store.state.showNav}">
    <div class="content">
      <div class="header-logo">
        <img src="@/assets/imgs/logo.png" alt="">
        <span>矩尺网络</span>
      </div>
      <div class="header-menu">
        <!-- 一级菜单 -->
        <div class="menu-box" v-for="menu in menus" :key="menu.path" @mouseenter="handleMenuHover(menu.meta.nameCN)" @mouseleave="handleMenuHover('')">
          <router-link 
            v-if="menu.path === '/'" 
            class="menu-l1"
            :class="{'is-active': activeName===menu.meta.nameCN}" 
            :to="{ path: menu.path }"
            >
            {{ menu.meta.nameCN }}
          </router-link>
          <router-link 
            v-else-if="!menu.meta.hasSubmenu && menu.children && menu.children.length" 
            class="menu-l1"
            :class="{'is-active': activeName===menu.meta.nameCN}" 
            :to="{ path: menu.path }"
            >
            {{ menu.meta.nameCN }}
          </router-link>
          <span v-else-if="menu.meta.hasSubmenu" class="menu-l1" :class="{'is-active': activeName===menu.meta.nameCN}">{{ menu.meta.nameCN }}</span> 
          <!-- 二级菜单 -->
          <template v-if="menu.meta.hasSubmenu && menu.children && menu.children.length">
            <el-collapse-transition>
              <ul class="sub-menu-box" v-show="hoverActiveName===menu.meta.nameCN">
                <li 
                  v-for="subMenu in menu.children" 
                  @click="handleSubMenuClick(subMenu.path)"
                  >
                  <!-- <a 
                    class="menu-l1"
                    :class="{'is-active': activeNameSub===subMenu.name}"
                    :href="origin + subMenu.path + '/index.html'"
                  >
                    {{ subMenu.meta.nameCN}}
                  </a> -->
                  <router-link 
                    :class="{'is-active': activeNameSub===subMenu.name}"
                    class="menu-l1"
                    :to="{ path: subMenu.path }"
                    >
                    {{ subMenu.meta.nameCN}}
                  </router-link>
                </li>
              </ul>
            </el-collapse-transition>
          </template>
        </div>
      </div>
      <div class="color-image-line"></div>
    </div>
  </nav>
</template>

<script>
import menus from '@/router/menuRoutes.js'

export default {
  name: 'JcNav',
  data () {
    return {
      menus: menus,
      hoverActiveName: '',
      activeName: '首页',
      activeNameSub: '',
      preScrollTop: 0,
      origin: location.origin
    }
  },
  watch: {
    $route: {
      handler (val) {
        // 设置菜单高亮
        if (val.meta.isSubmenu) {
          this.activeName = val.matched[0].meta.nameCN
          this.activeNameSub = val.name
        } else if (!val.hasSubmenu) {
          this.activeName = val.matched[0].meta.nameCN
          this.activeNameSub = ''
        } 
      },
      immediate: true
    }
  },
  mounted() {
    // 固定导航
    window.onscroll = this.scrollFun
  },
  methods: {
    scrollFun() {
      const curScrollTop = document.documentElement.scrollTop
      if(curScrollTop > 0 && this.preScrollTop < curScrollTop) { 
        //向下滚动,隐藏导航
        this.$store.commit('Set_ShowNav', false)
      } else if (curScrollTop > 0 && this.preScrollTop > curScrollTop) {
        this.$store.commit('Set_ShowNav', true)
      } else if(curScrollTop <= 0) {
        this.$store.commit('Set_ShowNav', true)
      }
      this.preScrollTop = curScrollTop
    },
    handleMenuHover(menuName) {
      this.hoverActiveName = menuName
    },
    handleSubMenuClick(path) {
      if(this.$route.path === path) return
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.nav-wrap{
  position: fixed;
  z-index: 999;
  color: #717174;
  width: 100%;
  min-width: 1440px;
  height: 75px;
  top: -75px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  transition: all .3s;
  .content{
    width: 1440px;
    height: 100%;
    white-space: nowrap;
    a {
      color: #717174;
    }
    .color-image-line{
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .header-logo {
      float: left;
      margin: 0 215px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img { 
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
      span {
        font-size: $logoTextSize;
      }
    }
    .header-menu {
      float: left;
      height: 100%;
      .menu-box {
        height: 100%;
        line-height: 75px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 0 25px;
        position: relative;
        .menu-l1{
          display: inline-block;
          height: 24px;
          line-height: 24px;
          // color: #717174;
          font-size: 16px;
          cursor: pointer;
          background: linear-gradient(to right, $primaryColor, $primaryColor) no-repeat right bottom;
          background-size: 0 2px;
          &:not(.is-active):hover {
            background-position: left bottom;
            background-size: 100% 2px;
            color: $primaryColor;
          }
          transition: background-size .2s;
        }
        .is-active {
          color: $primaryColor;
        }
        .sub-menu-box{
          width: 114px;
          padding: 0 0 7px 0;
          position: absolute;
          top: 75px;
          left: 0;
          background-color: #fff;
          opacity: 0.9;
          z-index: 999;
          transition: all .1s;
          li {
            font-size: 14px;
            line-height: 28px;
            &:hover{
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
}
.fixed-nav {
  top: 0;
}
</style>
